<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div class="col-md-11">
              <h1>Salary Component</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'"
                      :label="'+'"
                      @onClick="Add"
                      class="mr-2" />
            </div>
          </div>
          <v-text-field v-model="searchPersonnel"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details></v-text-field>
          <v-data-table loading-text="Loading salaryComponents... Please wait"
                      :loading="!salaryComponents.length"
                        :headers="headers" :items="salaryComponents"  :search="searchPersonnel">
              <template v-slot:item.description="{ item }">
               <button class="view-button" v-on:click="viewDetail(item)">{{item.description}}</button>
             </template>
            <template v-slot:item.dateAdded="{ item }">
              {{ item.dateAdded | formatDate }}
            </template>
            <template v-slot:item.salaryComponentType="{ item }">
              {{ SalaryComponentEnums (item.salaryComponentType) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'"
                        :label="'Edit'"
                        @onClick="edit(item)"
                        class="mr-2" />
                <Button :btnType="'Cancel'"
                        :label="'Delete'"
                        @onClick="openDeleteModal(item)" />
              </div>
            </template>
          </v-data-table>

           <!-- Dialog for view  -->
          <Dialog ref="viewModal" :title="'View'" :width="600">
              <v-row>
                <v-col>
                  <TextField v-model="formData.description" :disable="true" :label="'Name'" />
                </v-col>
                <v-col>
                  <v-select v-model="formData.SalaryComponentFrequencyId" disabled :items="frequency" item-value="id" item-text="name" label="Frequency" return-object outlined dense />
                  <!-- <p>{{formData.SalaryComponentFrequencyId}}</p> -->
                </v-col>
                <v-col>
                  <v-select v-model="formData.salaryComponentType" disabled :items="salaryComponentType" item-value="id" item-text="name" label="Salary Component Type" return-object outlined dense />
                </v-col>
                <v-col v-if="selected ===  4 ">
                  <TextField v-model="formData.rate" :disable="true" :type="'number'" :label="'Rate'" />
                </v-col>
                <v-col>
                  <TextField v-model="formData.markup" :disable="true" :type="'number'"
                             :label="'Markup'" />
                </v-col>
                <v-col dense>
                  <v-radio-group v-model="formData.isTimeSheetDriven" disabled :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Timesheet ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="6" v-if="formData.isTimeSheetDriven === true">
                  <v-select v-model="formData.TimesheetItemType" disabled :items="TimesheetItemType" item-value="id" item-text="name" label="Timesheet Item type" return-object outlined dense />
                </v-col>
                <v-col cols="6"></v-col>
                <v-col cols="6" v-if="formData.TimesheetItemType.id ===  1 ">
                  <v-select v-model="formData.overtimeType" disabled :items="OverTimeType" item-value="id" item-text="name" label="Overtime Type" return-object outlined dense />
                </v-col>

                <v-col>
                  <v-radio-group v-if="formData.isAllowance === false" disabled v-model="formData.isBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="CMS ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isFlat" :mandatory="false" disabled class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Fixed Amount ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isBaseOnBasic" disabled :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Based on CMS ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isTaxable" disabled :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Taxable ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
          </Dialog>

          <!-- Dialog for edit  -->
          <Dialog ref="editModal" :title="'Edit'" :width="600">
            <v-form ref="department" v-model="valid">
              <v-row>
                <v-col>
                  <TextField v-model="formData.description" :label="'Name'" />
                </v-col>
                <v-col>
                  <v-select v-model="formData.SalaryComponentFrequencyId" :items="frequency" item-value="id" item-text="name" label="Frequency" return-object outlined dense />
                  <!-- <p>{{formData.SalaryComponentFrequencyId}}</p> -->
                </v-col>
                <v-col >
                  <v-select v-model="formData.salaryComponentType" :items="salaryComponentType" @change="OnchangeSalaryComponent" item-value="id" item-text="name" label="Salary Component Type" return-object outlined dense />
                </v-col>
                 <v-col v-if="selected ===  4 ">
                  <TextField v-model="formData.rate" :type="'number'" :label="'Rate'" />
                </v-col>
                <v-col>
                  <TextField v-model="formData.markup" :type="'number'"
                             :label="'Markup'" />
                </v-col>
                <v-col dense>
                  <v-radio-group v-model="formData.isTimeSheetDriven" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Timesheet ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="6" v-if="formData.isTimeSheetDriven === true">
                  <v-select v-model="formData.TimesheetItemType" :items="TimesheetItemType" item-value="id" item-text="name" label="Timesheet Item type" return-object outlined dense />
                </v-col>
                <v-col cols="6"></v-col>
                <v-col cols="6" v-if="formData.TimesheetItemType.id ===  1 ">
                  <v-select v-model="formData.overtimeType" :items="OverTimeType" item-value="id" item-text="name" label="Overtime Type" return-object outlined dense />
                </v-col>

                <v-col>
                  <v-radio-group v-if="formData.isAllowance === false" v-model="formData.isBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="CMS ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isFlat" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Fixed Amount ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isBaseOnBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Based on CMS ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="formData.isTaxable" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Taxable ?">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <template v-slot:footer>
              <Button :btnType="'Submit'"
                      :label="'Update'"
                      @onClick="dialog = true"
                      class="mr-2" />
              <Button :btnType="'Cancel'"
                      :label="'Cancel'"
                      @onClick="cancel('editModal')" />
            </template>
          </Dialog>

          <!-- confirm delete modal  -->
          <Dialog ref="deleteModal"
                  :title="'Are you sure you want to delete'"
                  :width="400">
            <template v-slot:footer>
              <Button :btnType="'Submit'"
                      :label="'Yes'"
                      @onClick="deleteSalaryComponent"
                      class="mr-2" />
              <Button :btnType="'Cancel'"
                      :label="'No'"
                      @onClick="cancel('deleteModal')" />
            </template>
          </Dialog>

          <!-- add modal  -->
          <Dialog ref="addModal" :title="'Add Department'" :width="600">
          </Dialog>

          <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="update" />
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Dialog from '@/components/ui/Dialog.vue'
import {
  CREATE_SALARY,
  FETCH_SALARY,
  DELETE_SALARY
} from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    Dialog,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      isLoading: false,
      dialog: false,
      selected: 0,
      formData: {
        id: '',
        description: '',
        isTimeSheetDriven: null,
        isFlat: null,
        TimesheetItemType: 0,
        OverTimeType: 0,
        isBaseOnBasic: null,
        rate: 0,
        SalaryComponentFrequencyId: null,
        isTaxable: null,
        markup: 0,
        salaryComponentType: null
      },
      searchPersonnel: '',
      valid: false,
      isSaving: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      salaryComponentType: [{ id: 1, name: 'ConsolidatedSalary' }, { id: 2, name: 'Allowance' }, { id: 3, name: 'Benefit' }, { id: 4, name: 'Statutory' }, { id: 5, name: 'Deduction' }],
      frequency: [{ id: 1, name: 'Daily' }, { id: 2, name: 'weekly' }, { id: 3, name: 'Monthly' }, { id: 4, name: 'Yearly' }, { id: 5, name: 'One Time' }],
      TimesheetItemType: [{ id: 1, name: 'Overtime' }, { id: 2, name: 'Shift' }],
      OverTimeType: [{ id: 1, name: 'weekday' }, { id: 2, name: 'weekend' }],
      headers: [
        {
          text: 'Description',
          value: 'description'
        },

        {
          text: 'Salary Component Type',
          value: 'salaryComponentType'
        },
        {
          text: 'Added By',
          value: 'addedBy'
        },
        {
          text: 'Date Added',
          value: 'dateAdded'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Modified Date',
          value: 'lastModifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    salaryComponents () {
      return this.$store.getters.salaryComponents
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    OnchangeSalaryComponent () {
      this.selected = this.formData.salaryComponentType.id
    },
    viewDetail (item) {
      this.getInfo(item)
      this.$refs.viewModal.openModal()
    },
    getInfo (item) {
      console.log(item)
      this.formData.id = item.id
      this.formData.isTaxable = item.isTaxable
      this.formData.isBaseOnBasic = item.isBasedOnBasic
      this.formData.isFlat = item.isFlat
      this.formData.isTimeSheetDriven = item.isTimeSheetDriven
      this.formData.rate = item.rate
      // this.formData.TimesheetItemType = item.TimesheetItemType
      this.formData.description = item.description
      const SalaryComponent = this.salaryComponentType.filter(sal => sal.id === item.salaryComponentType)

      const frequency = this.frequency.filter(freq => freq.id === item.salaryComponentFrequencyId)
      this.formData.SalaryComponentFrequencyId = frequency[0]
      if (item.timesheetItemType !== 0) {
        const Timesheettype = this.TimesheetItemType.filter(timeSheet => timeSheet.id === item.timesheetItemType)
        this.formData.TimesheetItemType = Timesheettype[0]
      }
      if (item.overtimeType !== 0) {
        const overTime = this.OverTimeType.filter(overtime => overtime.id === item.overtimeType)
        this.formData.OvertimeType = overTime[0]
      }

      this.formData.salaryComponentType = SalaryComponent[0]
      console.log(this.formData.salaryComponentType)
      this.selected = this.formData.salaryComponentType.id

      // console.log('component', this.formData.salaryComponentType)
      // console.log('component Id', this.formData.salaryComponentType.id)
      this.formData.markup = item.markup
    },
    edit (item) {
      this.getInfo(item)
      this.$refs.editModal.openModal()
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.openModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    SalaryComponentEnums (value) {
      if (value === 1) {
        return 'ConsolidatedSalary'
      }
      if (value === 2) {
        return 'Allowance'
      }
      if (value === 3) {
        return 'Benefit'
      }
      if (value === 4) {
        return 'Statutory'
      }
      if (value === 5) {
        return 'Deduction'
      }
    },
    deleteSalaryComponent () {
      this.$store.dispatch(DELETE_SALARY, this.formData.id).then(() => {
        this.showAlertMessage('Salary component delete successful', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Salary component delete failed', 'error')
      }).finally(() => {
        this.$refs.deleteModal.closeModal()
      })
    },
    createDepartment () {

    },
    update () {
      this.isLoading = true
      const data = {
        'action': 'Edit',
        'id': this.formData.id,
        'description': this.formData.description,
        'isTimeSheetDriven': this.formData.isTimeSheetDriven,
        'isFlat': this.formData.isFlat,
        'isBasedOnBasic': this.formData.isBaseOnBasic,
        'salaryComponentFrequencyId': this.formData.SalaryComponentFrequencyId.id,
        'isTaxable': this.formData.isTaxable,
        'markup': this.formData.markup,
        'salaryComponentType': this.formData.salaryComponentType.id,
        'TimesheetItemType': this.formData.TimesheetItemType === 0 ? 0 : this.formData.TimesheetItemType.id,
        'OvertimeType': this.formData.OverTimeType === 0 ? 0 : this.formData.OverTimeType.id
      }

      console.log(data)
      this.$store.dispatch(CREATE_SALARY, data).then(() => {
        this.showAlertMessage('Salary component updated successful', 'success')
      }).catch((error) => {
        if (error.message === 'PendingRequestExists') { this.showAlertMessage('Salary Component Pending Approval', 'error') } else {
          this.showAlertMessage('Salary Component Update Failed', 'error')
        }
      }).finally(() => {
        this.isSavingUser = false
        this.isLoading = false
        this.$refs.editModal.closeModal()
        this.dialog = false
        this.onReset()
      })
    },
    Add () {
      this.$router.push('/salary/add')
    },
    closeAddModal () {
      this.$refs.department.reset()
      this.$refs.addModal.closeModal()
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.closeModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.closeModal()
      }
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_SALARY)
      .then(() => {})
      .catch(error => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.view-button {
  text-align: left;
  border: none;
  color: #0066b2;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
